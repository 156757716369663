import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "font-awesome/css/font-awesome.min.css";
import ChevronYellow from "@/components/shared/Icons/ChevronYellow.vue";
import ChevronWhite from "@/components/shared/Icons/ChevronWhite.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa4",
    values: {
      chevronYellow: {
        component: ChevronYellow,
      },
      chevronWhite: {
        component: ChevronWhite,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#FAE444",
        yellow: "#F4CD51",
        secondary: "#223542",
        tertiary: "#EDB55E",
        blackGray: "#313131",
        strongGray: "#919191",
        grayOne: "#F2F2F2",
        grayTwo: "#E6E6E6",
        grayThree: "#C2C2C2",
      },
    },
  },
});
