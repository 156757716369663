<template>
  <div
    id="about-us"
    class="blue-background d-flex align-center pt-6 pb-14 py-md-14 px-8 px-md-16"
  >
    <div class="px-md-10">
      <h2 class="text-center text-md-start text-h2 yellow--text mt-8 mb-3">
        {{ $t("aboutUs.title") }}
      </h2>
      <p class="text-body-1 text-md-subtitle-1 white--text mb-md-2">
        {{ $t("aboutUs.description.first") }}
      </p>
      <p
        class="text-body-1 text-md-subtitle-1 font-weight-semibold white--text"
      >
        {{ $t("aboutUs.description.second") }}
      </p>
      <div
        class="d-flex flex-wrap justify-space-around align-baseline my-8 px-8 px-md-0"
      >
        <div v-for="(topic, i) in topics" :key="i" class="pa-3">
          <v-img
            class="mx-auto"
            :src="topic.icon"
            :width="
              $vuetify.breakpoint.xs ? topic.mobileIconWidth : topic.iconWidth
            "
            :alt="$t(topic.iconAlt)"
          ></v-img>
          <p
            class="text-caption text-md-subtitle-2 font-weight-bold text-center white--text mt-4"
            style="display: table-caption"
          >
            {{ $t(topic.name) }}
          </p>
        </div>
      </div>
      <div
        v-if="$vuetify.breakpoint.xs"
        class="cursor-pointer link-button d-flex justify-center align-center text-body-1 font-weight-semibold white--text"
        @click="handleServicesRoute"
      >
        <span>{{ $t("reinforcingStatement.linkButton") }}</span>
        <v-icon size="18" class="chevron ml-3" :alt="$t('aboutUs.chevronAlt')">
          $chevronYellow
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import StrategicPlanningIcon from "@/assets/icons/StrategicPlanningIcon.svg";
import FinanceIcon from "@/assets/icons/FinanceIcon.svg";
import LegalIcon from "@/assets/icons/LegalIcon.svg";
import InnovationIcon from "@/assets/icons/InnovationIcon.svg";
import OrganizationalCultureIcon from "@/assets/icons/OrganizationalCultureIcon.svg";
import MarketingIcon from "@/assets/icons/MarketingIcon.svg";

export default {
  name: "AboutUs",
  data() {
    return {
      topics: [
        {
          name: "aboutUs.topics.first.name",
          icon: StrategicPlanningIcon,
          iconWidth: "26.32",
          mobileIconWidth: "21",
          iconAlt: "aboutUs.topics.first.iconAlt",
        },
        {
          name: "aboutUs.topics.second.name",
          icon: FinanceIcon,
          iconWidth: "52.09",
          mobileIconWidth: "31.7",
          iconAlt: "aboutUs.topics.second.iconAlt",
        },
        {
          name: "aboutUs.topics.third.name",
          icon: LegalIcon,
          iconWidth: "48.72",
          mobileIconWidth: "40.39",
          iconAlt: "aboutUs.topics.third.iconAlt",
        },
        {
          name: "aboutUs.topics.fourth.name",
          icon: InnovationIcon,
          iconWidth: "45.6",
          mobileIconWidth: "33.37",
          iconAlt: "aboutUs.topics.fourth.iconAlt",
        },
        {
          name: "aboutUs.topics.fifth.name",
          icon: OrganizationalCultureIcon,
          iconWidth: "38.11",
          mobileIconWidth: "34.05",
          iconAlt: "aboutUs.topics.fifth.iconAlt",
        },
        {
          name: "aboutUs.topics.sixth.name",
          icon: MarketingIcon,
          iconWidth: "35",
          mobileIconWidth: "30.36",
          iconAlt: "aboutUs.topics.sixth.iconAlt",
        },
      ],
    };
  },
  methods: {
    handleServicesRoute() {
      this.$router.push({ name: "Services" });
      window.scroll({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-background {
  min-height: 611px;
  background: url("../../assets/ILUM/INICIO/FONDO_AZUL_ONDAS.png") no-repeat
    center center !important;
  background-size: cover !important;
}

.cursor-pointer {
  cursor: pointer;
}

.link-button:hover .chevron {
  animation: shaking 0.5s infinite;
}

@keyframes shaking {
  0% {
    transform: rotateZ(8deg);
  }
  50% {
    transform: rotateZ(-8deg);
  }
  100% {
    transform: rotateZ(8deg);
  }
}
</style>
