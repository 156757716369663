<template>
  <v-sheet :height="$vuetify.breakpoint.xs ? 292 : 524">
    <iframe
      width="100%"
      :height="$vuetify.breakpoint.xs ? 292 : 524"
      frameborder="0"
      scrolling="no"
      marginheight="0"
      marginwidth="0"
      :src="`https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=${$i18n.locale}&amp;q=Paseos%20Vista%20del%20Sol%206801,%20Vista%20del%20Sol,%2031206%20Chihuahua,%20Chih.%20Ilump%20Prosperity%20Sherpas)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
    >
    </iframe>
  </v-sheet>
</template>

<script>
export default {
  name: "Map",
};
</script>
