<template>
  <v-sheet
    color="yellow"
    class="d-flex flex-column justify-center px-5"
    :height="$vuetify.breakpoint.xs ? 285 : 477"
  >
    <div
      class="d-flex flex-column align-center text-center white--text font-weight-bold mb-3 mb-md-12"
    >
      <h2 class="text-subtitle-1 text-sm-h2 mb-3 font-weight-semibold">
        {{ $t("allies.title") }}
      </h2>
      <h3 class="text-body-1 text-sm-subtitle-1 secondary--text">
        {{ $t("allies.subtitle.first") }}
        <span
          :class="
            $vuetify.breakpoint.xs ? 'font-weight-bold' : 'font-weight-semibold'
          "
        >
          {{ $t("allies.subtitle.second") }}
        </span>
      </h3>
    </div>
    <!-- Desktop -->
    <div
      v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      class="d-flex flex-wrap justify-center align-center px-2"
    >
      <div v-for="(company, i) in companies" :key="i" class="px-10">
        <img width="150" :src="company.logo" :alt="$t(company.logoAlt)" />
      </div>
    </div>
    <!-- Mobile -->
    <div v-else>
      <v-hover v-slot="{ hover }">
        <div>
          <v-window v-model="window" class="py-2">
            <v-window-item v-for="(n, i) in windows" :key="i">
              <v-sheet
                color="transparent"
                :height="$vuetify.breakpoint.xs ? 59 : 110"
                class="d-flex justify-space-around align-center px-0 px-sm-5"
              >
                <div
                  v-for="(company, j) in companies.slice(2 * i, 2 * n)"
                  :key="j"
                  class="px-6"
                >
                  <img
                    :width="n == 3 && $vuetify.breakpoint.xs ? 100 : '100%'"
                    :src="company.logo"
                    :alt="$t(company.logoAlt)"
                  />
                </div>
              </v-sheet>
            </v-window-item>
          </v-window>
          <v-sheet
            color="transparent"
            height="20"
            class="d-flex justify-center align-center px-10 px-sm-8"
            :class="hover && 'justify-space-between'"
          >
            <v-slide-x-reverse-transition hide-on-leave>
              <span v-show="hover" class="cursor-pointer" @click="prev">
                <v-icon
                  color="white"
                  :size="$vuetify.breakpoint.xs ? 20 : 25"
                  :alt="$t('allies.alts.chevron')"
                >
                  fa-chevron-left
                </v-icon>
              </span>
            </v-slide-x-reverse-transition>
            <v-item-group
              v-model="window"
              class="d-flex justify-center"
              mandatory
            >
              <v-item
                v-for="i in windows"
                :key="i"
                v-slot:default="{ active, toggle }"
                class="mx-1"
              >
                <v-sheet
                  color="white"
                  :style="!active && 'opacity: 0.6;'"
                  rounded
                  width="8"
                  height="8"
                  class="cursor-pointer"
                  @click="toggle"
                />
              </v-item>
            </v-item-group>
            <v-slide-x-transition hide-on-leave>
              <span v-show="hover" class="cursor-pointer" @click="next">
                <v-icon
                  color="white"
                  :size="$vuetify.breakpoint.xs ? 20 : 25"
                  :alt="$t('allies.alts.chevron')"
                >
                  fa-chevron-right
                </v-icon>
              </span>
            </v-slide-x-transition>
          </v-sheet>
        </div>
      </v-hover>
    </div>
  </v-sheet>
</template>

<script>
import BaleroLogo from "@/assets/ILUM/INICIO/BALERO.png";
import MorganLogo from "@/assets/ILUM/INICIO/MORGAN.png";
import ZumaLogo from "@/assets/ILUM/INICIO/ZUMA.png";
import MadnessLogo from "@/assets/ILUM/INICIO/MADNESS.png";
import HighSQLogo from "@/assets/ILUM/INICIO/HIGHSQ.png";

export default {
  name: "Allies",
  data() {
    return {
      companies: [
        {
          logo: BaleroLogo,
          logoAlt: "allies.alts.baleroVentures",
        },
        {
          logo: MorganLogo,
          logoAlt: "allies.alts.moganStanley",
        },
        {
          logo: ZumaLogo,
          logoAlt: "allies.alts.zumaCapital",
        },
        {
          logo: MadnessLogo,
          logoAlt: "allies.alts.madness",
        },
        {
          logo: HighSQLogo,
          logoAlt: "allies.alts.highSq",
        },
      ],
      window: 0,
      windows: 3,
    };
  },
  methods: {
    next() {
      this.window = this.window + 1 === this.windows ? 0 : this.window + 1;
    },
    prev() {
      this.window = this.window - 1 < 0 ? this.windows - 1 : this.window - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
