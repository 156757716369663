<template>
  <v-app>
    <v-main>
      <Navbar />
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/shared/Navbar.vue";
import Footer from "@/components/shared/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
@import "styles/variables";
</style>
