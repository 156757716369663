<template>
  <div>
    <HomeHeader />
    <AboutUs />
    <ServicesStairs />
    <span class="hidden-xs-only"><ReinforcingStatement /></span>
    <Allies />
    <Customers />
    <ContactForm />
    <Map />
  </div>
</template>

<script>
import HomeHeader from "@/components/home/HomeHeader.vue";
import AboutUs from "@/components/home/AboutUs.vue";
import ServicesStairs from "@/components/shared/ServicesStairs.vue";
import ReinforcingStatement from "@/components/home/ReinforcingStatement.vue";
import Allies from "@/components/shared/Allies.vue";
import Customers from "@/components/shared/Customers.vue";
import Map from "@/components/shared/Map.vue";
import ContactForm from "@/components/shared/ContactForm.vue";

export default {
  name: "Home",
  components: {
    HomeHeader,
    AboutUs,
    ServicesStairs,
    ReinforcingStatement,
    Allies,
    Customers,
    ContactForm,
    Map,
  },
};
</script>
