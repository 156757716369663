<template>
  <v-sheet
    color="white"
    class="d-flex flex-column justify-center px-5 py-md-16"
    :min-height="$vuetify.breakpoint.xs ? 322 : 498"
  >
    <div class="text-center mb-10 mb-md-12">
      <h2
        class="text-subtitle-1 text-sm-h2 secondary--text font-weight-semibold"
      >
        {{ $t("customers.title") }}
      </h2>
    </div>
    <div
      v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
      class="d-flex flex-wrap justify-center align-center px-2"
    >
      <div v-for="(customer, i) in customers" :key="i" class="px-10">
        <img
          :max-width="customer.logoWidth"
          :src="customer.image"
          :alt="$t(customer.logoAlt)"
        />
      </div>
    </div>
    <div v-else>
      <v-hover v-slot="{ hover }">
        <div>
          <v-window v-model="window" class="py-2">
            <v-window-item v-for="(n, i) in windows" :key="i">
              <v-sheet
                color="transparent"
                :height="$vuetify.breakpoint.xs ? 76 : 100"
                class="d-flex justify-space-around align-center px-0 px-sm-5"
              >
                <div
                  v-for="(customer, j) in customers.slice(2 * i, 2 * n)"
                  :key="j"
                  class="px-6"
                >
                  <img
                    :width="$vuetify.breakpoint.xs ? 96 : 146"
                    :src="customer.image"
                    :alt="$t(customer.logoAlt)"
                  />
                </div>
              </v-sheet>
            </v-window-item>
          </v-window>
          <v-sheet
            color="transparent"
            height="20"
            class="d-flex justify-center align-center px-10 px-sm-8"
            :class="hover && 'justify-space-between'"
          >
            <v-slide-x-reverse-transition hide-on-leave>
              <span v-show="hover" class="cursor-pointer" @click="prev">
                <v-icon
                  color="secondary"
                  :size="$vuetify.breakpoint.xs ? 20 : 25"
                  :alt="$t('customers.alts.chevron')"
                >
                  fa-chevron-left
                </v-icon>
              </span>
            </v-slide-x-reverse-transition>
            <v-item-group
              v-model="window"
              class="d-flex justify-center"
              mandatory
            >
              <v-item
                v-for="i in windows"
                :key="i"
                v-slot:default="{ active, toggle }"
                class="mx-1"
              >
                <v-sheet
                  color="secondary"
                  :style="!active && 'opacity: 0.6;'"
                  rounded
                  width="8"
                  height="8"
                  class="cursor-pointer"
                  @click="toggle"
                />
              </v-item>
            </v-item-group>
            <v-slide-x-transition hide-on-leave>
              <span v-show="hover" class="cursor-pointer" @click="next">
                <v-icon
                  color="secondary"
                  :size="$vuetify.breakpoint.xs ? 20 : 25"
                  :alt="$t('customers.alts.chevron')"
                >
                  fa-chevron-right
                </v-icon>
              </span>
            </v-slide-x-transition>
          </v-sheet>
        </div>
      </v-hover>
    </div>
  </v-sheet>
</template>

<script>
import TransportesSotoLogo from "@/assets/ILUM/INICIO/TSOTO.png";
import GCCLogo from "@/assets/ILUM/INICIO/GCC.png";
import HospitalAngelesLogo from "@/assets/ILUM/INICIO/ANGELES.png";
import InnovakLogo from "@/assets/ILUM/INICIO/INNOVAK.png";

export default {
  name: "Customers",
  data() {
    return {
      customers: [
        {
          image: TransportesSotoLogo,
          logoWidth: 222,
          logoAlt: "customers.alts.transportesSoto",
        },
        {
          image: GCCLogo,
          logoWidth: 222,
          logoAlt: "customers.alts.gcc",
        },
        {
          image: HospitalAngelesLogo,
          logoWidth: 226,
          logoAlt: "customers.alts.hospitalAngeles",
        },
        {
          image: InnovakLogo,
          logoWidth: 262,
          logoAlt: "customers.alts.innovak",
        },
      ],
      window: 0,
      windows: 2,
    };
  },
  methods: {
    next() {
      this.window = this.window + 1 === this.windows ? 0 : this.window + 1;
    },
    prev() {
      this.window = this.window - 1 < 0 ? this.windows - 1 : this.window - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
