<template>
  <div
    class="fill-height blue-background d-flex flex-column align-center justify-center text-center px-8 px-md-16"
  >
    <h2 class="text-h2 yellow--text mb-6">
      {{ $t("reinforcingStatement.title.first") }}
      <span class="white--text">
        {{ $t("reinforcingStatement.title.second") }}
      </span>
    </h2>
    <p
      class="d-flex flex-column text-subtitle-1 font-weight-semibold white--text mb-6 px-10"
    >
      {{ $t("reinforcingStatement.subtitle.first") }}
      <span>
        {{ $t("reinforcingStatement.subtitle.second") }}
      </span>
    </p>
    <div
      class="cursor-pointer link-button d-flex align-center text-body-1 white--text"
      @click="handleServicesRoute"
    >
      <span>{{ $t("reinforcingStatement.linkButton") }}</span>
      <v-icon
        size="18"
        class="chevron ml-3"
        :alt="$t('reinforcingStatement.chevronAlt')"
      >
        $chevronYellow
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReinforcingStatement",
  methods: {
    handleServicesRoute() {
      this.$router.push({ name: "Services" });
      window.scroll({
        top: 0,
        left: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-background {
  min-height: 487px;
  background-color: rgb(37, 52, 66);
}

.cursor-pointer {
  cursor: pointer;
}

.link-button:hover .chevron {
  animation: shaking 0.5s infinite;
}

@keyframes shaking {
  0% {
    transform: rotateZ(8deg);
  }
  50% {
    transform: rotateZ(-8deg);
  }
  100% {
    transform: rotateZ(8deg);
  }
}
</style>
