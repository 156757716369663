<template>
  <footer class="secondary py-7 px-10">
    <v-container v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
      <v-row class="mt-5">
        <v-col cols="12" md="3" class="d-flex flex-row justify-space-around">
          <div class="mr-15">
            <v-img
              :src="IlumIcon"
              max-width="150"
              :alt="$t('footer.alts.ilumps')"
            />
          </div>
          <div>
            <div class="text-body-1 font-weight-semibold yellow--text mb-3">
              <p>{{ $t("footer.address") }}</p>
            </div>
            <a
              href="https://goo.gl/maps/wQTiVEhkVExwErbc7"
              target="_blank"
              class="text-decoration-none"
            >
              <div class="cursor-pointer white--text">
                <address class="text-subtitle-2">
                  Paseos Vistas del Sol 6801, Chihuahua, Chih., México
                </address>
              </div>
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-row justify-space-around">
          <div>
            <div class="text-body-1 font-weight-semibold yellow--text mb-3">
              <p>{{ $t("footer.phone") }}</p>
            </div>
            <a href="tel:+52 614 452 6128" class="text-decoration-none">
              <div class="text-subtitle-2 white--text">(614) 452 6128</div>
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-row justify-space-around">
          <div>
            <div class="text-body-1 font-weight-semibold yellow--text mb-3">
              <p>{{ $t("footer.email") }}</p>
            </div>
            <a href="mailto:info@ilumps.com" class="text-decoration-none">
              <div class="text-subtitle-2 white--text">info@ilumps.com</div>
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="3" class="d-flex flex-row justify-space-around">
          <div>
            <div class="text-body-1 font-weight-semibold yellow--text mb-3">
              <p>{{ $t("footer.socialMedia") }}</p>
            </div>
            <div class="d-flex flex-row">
              <a
                v-for="(platform, i) in socialMedia"
                :key="i"
                :href="platform.url"
                target="_blank"
                class="cursor-pointer text-decoration-none px-2"
              >
                <v-img
                  width="28"
                  :src="platform.icon"
                  :alt="$t(platform.iconAlt)"
                />
              </a>
            </div>
          </div>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-center text-center text-caption white--text mt-16"
      >
        {{ $t("footer.copyright") }}
      </div>
    </v-container>

    <div v-else>
      <v-img
        :src="IlumIcon"
        max-width="20"
        :alt="$t('footer.alts.ilumps')"
        class="mb-2"
      />
      <v-row align="center" no-gutters>
        <v-col cols="7">
          <div class="text-body-1 font-weight-semibold yellow--text mb-1">
            {{ $t("footer.contact") }}
          </div>
          <div>
            <a
              href="https://goo.gl/maps/wQTiVEhkVExwErbc7"
              target="_blank"
              class="text-subtitle-2 white--text text-decoration-none"
            >
              Paseos Vistas del Sol 6801, Chihuahua, Chih., México
            </a>
          </div>
          <div>
            <a
              href="tel:+52 614 452 6128"
              class="text-subtitle-2 white--text text-decoration-none"
            >
              (614) 452 6128
            </a>
          </div>
          <div>
            <a
              href="mailto:info@ilumps.com"
              class="text-subtitle-2 white--text text-decoration-none"
            >
              info@ilumps.com
            </a>
          </div>
        </v-col>
        <v-col cols="5" class="d-flex justify-end flex-wrap pb-13">
          <a
            v-for="(platform, i) in socialMedia"
            :key="i"
            :href="platform.url"
            target="_blank"
            class="cursor-pointer text-decoration-none pa-1"
          >
            <v-img
              width="28"
              contain
              :src="platform.icon"
              :alt="$t(platform.iconAlt)"
            />
          </a>
        </v-col>
      </v-row>
      <div
        class="d-flex justify-center text-center text-caption white--text mt-8"
      >
        {{ $t("footer.copyright") }}
      </div>
    </div>
  </footer>
</template>

<script>
import IlumIcon from "@/assets/ILUM/INICIO/LLAMA_ILUM.svg";
import FacebookIcon from "@/assets/ILUM/INICIO/FACEBOOK.svg";
import LinkedinIcon from "@/assets/ILUM/INICIO/LINKEDIN.svg";
import InstagramIcon from "@/assets/ILUM/INICIO/INSTAGRAM.svg";

export default {
  name: "Footer",
  data() {
    return {
      IlumIcon: IlumIcon,
      FacebookIcon: FacebookIcon,
      LinkedinIcon: LinkedinIcon,
      InstagramIcon: InstagramIcon,
      socialMedia: [
        {
          icon: FacebookIcon,
          url: "https://www.facebook.com/ilumps",
          iconAlt: "footer.alts.facebook",
        },
        {
          icon: LinkedinIcon,
          url: "https://www.linkedin.com/company/ilum-prosperity-sherpas/",
          iconAlt: "footer.alts.linkedin",
        },
        {
          icon: InstagramIcon,
          url: "https://www.instagram.com/ilum.prosperity.sherpas/",
          iconAlt: "footer.alts.instagram",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
