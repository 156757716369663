<template>
  <div
    class="white-background d-flex align-center justify-center px-8 px-sm-16"
  >
    <div class="my-13 mt-sm-16 mb-sm-11 mx-md-10 px-md-16">
      <h3
        v-if="$vuetify.breakpoint.xs"
        class="yellow--text text-h3 text-center mb-13"
      >
        {{ $t("servicesStairs.title") }}
      </h3>
      <v-row>
        <v-col cols="10" md="6">
          <div class="d-flex align-start">
            <v-icon
              color="tertiary"
              :size="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 27 : 49"
              :alt="$t('servicesStairs.circleAlt')"
              class="mr-4 mr-md-5"
            >
              fa fa-circle-o
            </v-icon>
            <div>
              <div class="secondary--text mb-2">
                <h3 class="text-subtitle-1 text-md-h2 font-weight-semibold">
                  {{ $t("servicesStairs.first.title") }}
                </h3>
              </div>
              <div class="text-subtitle-2 text-md-subtitle-1 blackGray--text">
                <p>
                  {{ $t("servicesStairs.first.description") }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2" md="6"></v-col>
        <v-col cols="1" md="2"></v-col>
        <v-col cols="10" md="8">
          <div class="d-flex align-start">
            <v-icon
              color="yellow"
              :size="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 27 : 49"
              :alt="$t('servicesStairs.circleAlt')"
              class="mr-4 mr-md-5"
            >
              fa fa-circle-o
            </v-icon>
            <div>
              <div class="secondary--text font-weight-bold mb-2">
                <h3 class="text-subtitle-1 text-md-h2 font-weight-semibold">
                  {{ $t("servicesStairs.second.title") }}
                </h3>
              </div>
              <div class="text-subtitle-2 text-md-subtitle-1 blackGray--text">
                <p>
                  {{ $t("servicesStairs.second.description") }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="2" md="5"></v-col>
        <v-col cols="10" md="7">
          <div class="d-flex align-start">
            <v-icon
              color="tertiary"
              :size="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 27 : 49"
              :alt="$t('servicesStairs.circleAlt')"
              class="mr-4 mr-md-5"
            >
              fa fa-circle-o
            </v-icon>
            <div>
              <div class="secondary--text font-weight-bold mb-2">
                <h3 class="text-subtitle-1 text-md-h2 font-weight-semibold">
                  {{ $t("servicesStairs.third.title") }}
                </h3>
              </div>
              <div class="text-subtitle-2 text-md-subtitle-1 blackGray--text">
                <p>
                  {{ $t("servicesStairs.third.description") }}
                </p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesStairs",
};
</script>

<style lang="scss" scoped>
.white-background {
  min-height: 569;
  background: url("../../assets/ILUM/INICIO/FONDO_BLANCO_ONDAS.png") no-repeat
    center center !important;
  background-size: cover !important;
}
</style>
