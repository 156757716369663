<template>
  <div
    id="contact-form"
    class="yellow-second d-flex align-center py-16 px-10 px-md-16"
  >
    <v-row class="py-md-10 px-md-16">
      <v-col cols="12" md="6">
        <h3
          class="text-center text-md-left text-subtitle-1 text-md-h2 font-weight-semibold white--text mb-5"
        >
          {{ $t("contact.title") }}
        </h3>
        <p
          class="text-subtitle-2 text-md-subtitle-1 white--text"
          :class="
            ($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) &&
            'font-weight-semibold'
          "
        >
          {{ $t("contact.description") }}
        </p>
        <div v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)">
          <div
            class="text-subtitle-1 text-md-subtitle-1 font-weight-semibold white--text mt-10"
          >
            <label for="">
              {{ $t("contact.address") }}
            </label>
          </div>
          <a
            href="https://goo.gl/maps/uAc9JZDUrVkGdjo48"
            target="_blank"
            class="text-md-body-1 white--text text-decoration-underline"
          >
            Paseos Vistas del Sol 6801, Chihuahua, Chih., México
          </a>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="pl-md-16">
        <v-form
          v-if="formActive"
          v-model="isFormValid"
          action="https://formsubmit.co/info@ilumps.com"
          method="POST"
        >
          <v-text-field
            v-model="name"
            solo
            :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 30 : 40"
            class="text-body-2 text-md-body-1 font-weight-medium rounded-lg mb-3"
            :placeholder="$t('contact.form.name')"
            :name="$t('contact.form.name')"
            hide-details="auto"
            :rules="nameRules"
          />
          <v-text-field
            v-model="phone"
            solo
            :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 30 : 40"
            class="text-body-2 text-md-body-1 font-weight-medium rounded-lg mb-3"
            :placeholder="$t('contact.form.phone')"
            :name="$t('contact.form.phone')"
            hide-details="auto"
            :rules="phoneRules"
          />
          <v-text-field
            v-model="email"
            type="email"
            solo
            :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 30 : 40"
            class="text-body-2 text-md-body-1 font-weight-medium rounded-lg mb-3"
            :placeholder="$t('contact.form.email')"
            :name="$t('contact.form.email')"
            hide-details="auto"
            :rules="emailRules"
          />
          <v-textarea
            v-model="comments"
            solo
            :height="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 92 : 116
            "
            class="text-body-2 text-md-body-1 font-weight-medium rounded-lg mb-5 mb-md-6"
            :placeholder="$t('contact.form.comments')"
            hide-details="auto"
            :name="$t('contact.form.comments')"
          />
          <v-btn
            type="submit"
            block
            x-large
            :disabled="!isFormValid"
            :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 40 : 48"
            class="text-subtitle-2 text-md-body-1 text-capitalize secondary"
            :class="
              $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
                ? 'font-weight-bold rounded-lg'
                : 'font-weight-semibold rounded-xl'
            "
          >
            {{ $t("contact.form.button") }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      isFormValid: false,
      formActive: true,
      name: "",
      phone: "",
      email: "",
      comments: "",
    };
  },
  computed: {
    nameRules() {
      return [(v) => !!v || this.$t("contact.form.rules.name")];
    },
    phoneRules() {
      return [
        (v) =>
          !v.trim() || /^\d+$/.test(v) || this.$t("contact.form.rules.phone"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("contact.form.rules.email.required"),
        (v) => /.+@.+/.test(v) || this.$t("contact.form.rules.email.invalid"),
      ];
    },
  },
  watch: {
    "$i18n.locale"() {
      this.formActive = false;
      this.$nextTick(() => {
        this.formActive = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.yellow-second {
  background: url("../../assets/ILUM/INICIO/FONDO_AMARILLO_ESTRUCTURA.png")
    no-repeat center center !important;
  background-size: cover !important;
}

.inputs-size {
  width: 30px;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
</style>
