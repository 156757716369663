<template>
  <div>
    <v-sheet
      :height="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 87 : 91"
      :color="!isScrolling ? 'transparent' : 'white'"
      class="navbar d-flex align-center px-10 px-md-16"
      :elevation="isScrolling ? 4 : 0"
    >
      <div>
        <v-img
          v-if="!isScrolling"
          :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 113 : 134"
          src="@/assets/ILUM/INICIO/LOGO_BLANCO.svg"
          :alt="$t('navbar.alts.ilumps')"
          class="cursor-pointer"
          @click="handleRoute('Home')"
        />
        <v-img
          v-else
          :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 113 : 134"
          src="@/assets/ILUM/INICIO/LOGO_AZUL.svg"
          :alt="$t('navbar.alts.ilumps')"
          class="cursor-pointer"
          @click="handleRoute('Home')"
        />
      </div>
      <v-spacer />
      <!-- Desktop -->
      <div
        v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        class="d-flex white--text text-body-1 font-weight-semibold"
        :class="isScrolling && 'strongGray--text'"
      >
        <span
          v-for="(tab, i) in navbar"
          :key="i"
          class="cursor-pointer ml-3 mr-3"
          :class="tab.route === $route.name && 'secondary--text'"
          @click="handleRoute(tab.route)"
        >
          {{ $t(tab.name) }}
        </span>
        <span class="d-flex align-center ml-3">
          <img
            width="20"
            height="20"
            :src="isScrolling ? WorldGreyIcon : WorldWhiteIcon"
            :alt="$t('navbar.alts.world')"
            class="mr-3"
          />
          <span
            class="mr-1"
            :class="
              $i18n.locale === 'es' ? 'secondary--text' : 'cursor-pointer'
            "
            @click="handleLocaleLanguage('es')"
          >
            ES
          </span>
          |
          <span
            class="ml-1"
            :class="
              $i18n.locale === 'en' ? 'secondary--text' : 'cursor-pointer'
            "
            @click="handleLocaleLanguage('en')"
          >
            EN
          </span>
        </span>
      </div>
      <!-- Mobile -->
      <div v-else>
        <v-dialog
          v-model="drawer"
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ props }">
            <div
              class="cursor-pointer pa-1"
              v-bind="props"
              @click="handleDrawer"
            >
              <v-img
                width="24"
                :src="!isScrolling ? MenuWhiteIcon : MenuBlueIcon"
                :alt="$t('navbar.alts.menu')"
              />
            </div>
          </template>
          <v-sheet class="px-10" height="100%">
            <v-sheet
              color="transparent"
              height="90"
              class="d-flex align-center justify-space-between"
            >
              <img
                width="113"
                src="@/assets/ILUM/INICIO/LOGO_AZUL.svg"
                :alt="$t('navbar.alts.ilumps')"
                class="cursor-pointer"
                @click="handleRoute('Home')"
              />
              <div class="cursor-pointer pa-1" @click.stop="handleDrawer">
                <v-img
                  width="24"
                  :src="MenuBlueIcon"
                  :alt="$t('navbar.alts.menu')"
                />
              </div>
            </v-sheet>
            <div class="text-body-1 secondary--text mt-14">
              <div
                v-for="(tab, i) in navbar"
                :key="i"
                class="py-2"
                :class="tab.route === $route.name && 'font-weight-bold'"
              >
                <span @click="handleRoute(tab.route)" class="cursor-pointer">
                  {{ $t(tab.name) }}
                </span>
              </div>
              <div class="d-flex align-center font-weight-semibold pt-2">
                <img
                  width="20"
                  height="20"
                  :src="WorldGreyIcon"
                  :alt="$t('navbar.alts.world')"
                  class="mr-3"
                />
                <span
                  class="mr-1"
                  :class="
                    $i18n.locale !== 'es' &&
                    'cursor-pointer strongGray--text opacity-text'
                  "
                  @click="handleLocaleLanguage('es')"
                >
                  ES
                </span>
                |
                <span
                  class="ml-1"
                  :class="
                    $i18n.locale !== 'en' &&
                    'cursor-pointer strongGray--text opacity-text'
                  "
                  @click="handleLocaleLanguage('en')"
                >
                  EN
                </span>
              </div>
            </div>
          </v-sheet>
        </v-dialog>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import WorldWhiteIcon from "@/assets/icons/WorldWhiteIcon.svg";
import WorldGreyIcon from "@/assets/icons/WorldGreyIcon.svg";
import MenuWhiteIcon from "@/assets/icons/MenuWhiteIcon.svg";
import MenuBlueIcon from "@/assets/icons/MenuBlueIcon.svg";

export default {
  name: "Navbar",
  data() {
    return {
      navbar: [
        { name: "navbar.aboutUs", route: "Home" },
        { name: "navbar.services", route: "Services" },
        { name: "navbar.ventureBuilder", route: "VentureBuilder" },
        { name: "navbar.contact", route: "Contact" },
      ],
      isScrolling: false,
      drawer: false,
      WorldWhiteIcon: WorldWhiteIcon,
      WorldGreyIcon: WorldGreyIcon,
      MenuWhiteIcon: MenuWhiteIcon,
      MenuBlueIcon: MenuBlueIcon,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleRoute(location) {
      if (location === "Contact") {
        this.handleScrollContact();
      } else if (location === "VentureBuilder") {
        window.open("https://ilum.vc/", "_blank");
      } else {
        if (this.$router.history.current.name !== location) {
          this.$router.push({ name: location });
        }
        window.scroll({
          top: 0,
          left: 0,
        });
      }

      this.drawer = false;
    },
    handleDrawer() {
      this.drawer = !this.drawer;
    },
    handleLocaleLanguage(locale) {
      this.$i18n.locale = locale;
    },
    handleScroll() {
      this.isScrolling = window.scrollY > 50;
    },
    handleScrollContact() {
      document
        .getElementById("contact-form")
        .scrollIntoView({ block: "center", behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}

.navbar {
  overflow: hidden;
  transition: 0.6s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9;
}

.opacity-text {
  opacity: 0.3;
}
</style>
