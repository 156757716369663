import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "@/locales/es.json";
import en from "@/locales/en.json";

Vue.use(VueI18n);

function getBrowserLocale() {
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  return navigatorLocale.trim().split(/-|_/)[0];
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale();

  if (["es", "en"].includes(browserLocale)) {
    return browserLocale;
  } else {
    return "es";
  }
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: "es",
  messages: {
    en,
    es,
  },
});
