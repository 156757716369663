import { render, staticRenderFns } from "./Allies.vue?vue&type=template&id=6a3ebb92&scoped=true&"
import script from "./Allies.vue?vue&type=script&lang=js&"
export * from "./Allies.vue?vue&type=script&lang=js&"
import style0 from "./Allies.vue?vue&type=style&index=0&id=6a3ebb92&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a3ebb92",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VHover,VIcon,VItem,VItemGroup,VSheet,VSlideXReverseTransition,VSlideXTransition,VWindow,VWindowItem})
